import { ActionContext } from "vuex";

export default {
  namespaced: true,
  state: {
    filter: {
      iccid: undefined,
      objectLabel: undefined,
      status: undefined,
      managementStatus: undefined,
      createdAtFrom: undefined,
      createdAtTo: undefined,
      updatedAtFrom: undefined,
      updatedAtTo: undefined,
    },
    items: [] as any[],
  },
  getters: {
    getItems: (state: any) => state.items || [],
  },
  mutations: {
    APPLY_FILTER: (state: any, payload: any) => (state.filter = payload),
    SET_ITEMS: (state: any, payload: any) => (state.items = payload),
  },
  actions: {
    applyFilter: ({ commit }: ActionContext<any, any>, payload: any) => commit("APPLY_FILTER", payload),
    refreshItems: ({ state, commit }: ActionContext<any, any>) => {
      commit("SET_ITEMS", [...state.items]);
    },
  },
};
