import { ActionContext } from "vuex";

export default {
  namespaced: true,
  state: {
    filter: {
      iccid: undefined,
      keyPairStatus: undefined,
      csrStatus: undefined,
      certStatus: undefined,
      managementStatus: undefined,
      createdAtFrom: undefined,
      createdAtTo: undefined,
      updatedAtFrom: undefined,
      updatedAtTo: undefined,
      validFromStart: undefined,
      validFromEnd: undefined,
      validToStart: undefined,
      validToEnd: undefined,
    },
    items: [] as any[],
    updated_index: -1,
    iot_safe_certificates_detail: undefined,
  },
  getters: {
    getItems: (state: any) => state.items || [],
    getUpdatedIndex: (state: any) => state.updated_index,
  },
  mutations: {
    APPLY_FILTER: (state: any, payload: any) => (state.filter = payload),
    SET_ITEMS: (state: any, payload: any) => (state.items = payload),
    SET_IOT_SAFE_CERTIFICATES_DETAIL: (state: any, payload: any) => (state.iot_safe_certificates_detail = payload),
    SET_UPDATED_INDEX: (state: any, payload: any) => (state.updated_index = payload),
  },
  actions: {
    applyFilter: ({ commit }: ActionContext<any, any>, payload: any) => commit("APPLY_FILTER", payload),

    setIotSafeCertificatesDetail: ({ commit }: ActionContext<any, any>, payload: any) => commit("SET_IOT_SAFE_CERTIFICATES_DETAIL", payload),
    refreshItems: ({ state, commit }: ActionContext<any, any>, payload: any) => {
      commit("SET_ITEMS", [...state.items]);
    },
    cleaUpdatedIndex: ({ commit }: ActionContext<any, any>, payload: any) => {
      commit("SET_UPDATED_INDEX", -1);
    },
  },
};
