import { computed } from "vue";
import { useStore } from "vuex";
import { PermissionKey } from "../../../common/src/enums";

export default function usePermission() {
  const store = useStore();
  const storePermissions = computed<string[]>(() => store.getters.getPermission);
  const hasPermission = (permissionName: string) => {
    return storePermissions.value.includes(permissionName);
  };
  const otaPermissions = computed(() => {
    return {
      create: hasPermission(PermissionKey.OTA__CREATE),
      update: hasPermission(PermissionKey.OTA__UPDATE),
      delete: hasPermission(PermissionKey.OTA__DELETE),
    };
  });
  const simPermissions = computed(() => {
    return {
      create: hasPermission(PermissionKey.SIM__CREATE),
      update: hasPermission(PermissionKey.SIM__UPDATE),
      delete: hasPermission(PermissionKey.SIM__DELETE),
    };
  });
  const iotSafeCertificatesPermissions = computed(() => {
    return {
      create: hasPermission(PermissionKey.IOTSAFE_CERTIFICATES__CREATE),
      update: hasPermission(PermissionKey.IOTSAFE_CERTIFICATES__UPDATE),
      delete: hasPermission(PermissionKey.IOTSAFE_CERTIFICATES__DELETE),
    };
  });
  const settingPermissions = computed(() => {
    return {
      theme: hasPermission(PermissionKey.SETTING__THEME),
      mail: hasPermission(PermissionKey.SETTING__SYSTEM_MAIL),
      passwordSecurity: hasPermission(PermissionKey.SETTING__PASSWORD_SECURITY),
      api: hasPermission(PermissionKey.OPENAPI_GENERATE),
      accessRestrictions: hasPermission(PermissionKey.SETTING__ACCESS_RESTRICTIONS),
    };
  });

  return {
    otaPermissions,
    simPermissions,
    iotSafeCertificatesPermissions,
    settingPermissions,
  };
}
