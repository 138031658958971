<template>
  <v-row class="px-2 py-0 mx-6">
    <v-col class="d-flex justify-start align-center">
      <div class="d-flex justify-start align-center">
        <router-link to="/" class="d-flex justify-start align-center">
          <span v-if="appLogo && appLogo.base64" class="app---logo">
            <img :src="appLogo.base64" cover="fill" />
          </span>
          <app-icon v-else icon="app-logo" class="d-flex justify-start align-center" />
        </router-link>
      </div>
      <div v-if="appName" class="pl-2 font-weight-bold app--theme-color">
        <router-link to="/" style="text-decoration: none; color: inherit">
          {{ appName }}
        </router-link>
      </div>
    </v-col>
    <v-col class="d-flex justify-end align-center">
      <div>
        <v-menu location="bottom" v-model="isOpenNotifications" :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-badge v-if="notificationCount" :content="notificationCount" color="#D32F2F" offset-x="-3" max="20">
              <v-icon v-bind="props">mdi-bell</v-icon>
            </v-badge>
            <v-icon v-else v-bind="props">mdi-bell</v-icon>
          </template>
          <v-sheet min-width="400" max-height="700">
            <div v-if="isEnableOTA" class="app-setting-tab app---setting-tab" style="overflow-x: auto">
              <v-tabs v-model="tab" align-with-title class="justify">
                <v-tab :style="`width: ${100 / tabs.length}%`" :key="`tab-item-sim`" :value="'sim'">
                  <template v-slot:default>
                    <span>SIM</span>
                    <v-badge v-if="simNotificationCount" :content="simNotificationCount" class="ml-4 mb-1" color="#D32F2F" max="20" />
                  </template>
                </v-tab>
                <v-tab :style="`width: ${100 / tabs.length}%`" :key="`tab-item-ota`" :value="'ota'">
                  <template v-slot:default>
                    <span>OTA</span>
                    <v-badge v-if="otaNotificationCount" :content="otaNotificationCount" class="ml-4 mb-1" color="#D32F2F" max="20" />
                  </template>
                </v-tab>
                <v-tab :style="`width: ${100 / tabs.length}%`" :key="`tab-item-iotsafe`" :value="'iotsafe'">
                  <template v-slot:default>
                    <span>IoT SAFE</span>
                    <v-badge v-if="iotsafeNotificationCount" :content="iotsafeNotificationCount" class="ml-4 mb-1" color="#D32F2F" max="20" />
                  </template>
                </v-tab>
              </v-tabs>
            </div>
            <v-window v-model="tab">
              <v-window-item value="sim">
                <v-list lines="three" v-if="simNotificationItems.length > 0">
                  <v-list-subheader v-if="!isEnableOTA && !isEnableIoTSAFE" lines="three" class="text-h6">{{ $t("Notifications") }}</v-list-subheader>
                  <v-list-item v-for="item in simNotificationItems" :key="`sim-${item.date}`" border link @click="notificationClickHandler(item.to)">
                    <v-sheet width="100%">
                      <p>{{ $t(item.title) }}</p>
                      <p class="text-body-2 pl-2 white-space-pre-wrap">{{ item.body }}</p>
                      <p class="text-body-2 pl-2 text-right">{{ showDate(item.date, "YYYY-MM-DD HH:mm:ss") }}</p>
                    </v-sheet>
                  </v-list-item>
                </v-list>
                <v-list v-else>
                  <v-list-item>
                    <v-list-item-subtitle class="text-h6">{{ $t("NoNotifications") }}</v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-window-item>
              <v-window-item value="ota">
                <v-list lines="three" v-if="otaNotificationItems.length > 0">
                  <v-list-item v-for="item in otaNotificationItems" :key="`ota-${item.date}`" border link @click="notificationClickHandler(item.to)">
                    <v-sheet width="100%">
                      <p>{{ $t(item.title) }}</p>
                      <p class="text-body-2 pl-2 white-space-pre-wrap">{{ item.body }}</p>
                      <p class="text-body-2 pl-2 text-right">{{ showDate(item.date, "YYYY-MM-DD HH:mm:ss") }}</p>
                    </v-sheet>
                  </v-list-item>
                </v-list>
                <v-list v-else>
                  <v-list-item>
                    <v-list-item-subtitle class="text-h6">{{ $t("NoNotifications") }}</v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-window-item>
              <v-window-item value="iotsafe">
                <v-list lines="three" v-if="iotsafeNotificationItems.length > 0">
                  <v-list-item v-for="item in iotsafeNotificationItems" :key="`iotsafe-${item.date}`" border link @click="notificationClickHandler(item.to)">
                    <v-sheet width="100%">
                      <p>{{ $t(item.title) }}</p>
                      <p class="text-body-2 pl-2 white-space-pre-wrap">{{ item.body }}</p>
                      <p class="text-body-2 pl-2 text-right">{{ showDate(item.date, "YYYY-MM-DD HH:mm:ss") }}</p>
                    </v-sheet>
                  </v-list-item>
                </v-list>
                <v-list v-else>
                  <v-list-item>
                    <v-list-item-subtitle class="text-h6">{{ $t("NoNotifications") }}</v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-window-item>
            </v-window>
          </v-sheet>
        </v-menu>
      </div>
      <div class="pl-6 py-2 app-header-menu d-flex align-center">
        <v-menu location-strategy="connected" scroll-strategy="close" class="app-header-menu-list">
          <template v-slot:activator="{ props }">
            <div v-bind="props" class="d-flex align-center cursor-pointer">
              <div>
                <v-icon>mdi-account-circle</v-icon>
              </div>
              <v-progress-circular v-if="email == $t('NoEmail') || name == $t('NoName')" class="ml-3 loading" :size="25" indeterminate></v-progress-circular>
              <div v-else class="pl-3">
                <div class="text-header-menu-email font-size-14 font-weight-400">{{ email }}</div>
                <div class="text-header-menu-name font-size-12 font-weight-400">{{ name }}</div>
              </div>
              <div class="pl-4">
                <v-icon v-if="props['aria-expanded'] == 'true'">mdi-menu-up</v-icon>
                <v-icon v-else>mdi-menu-down</v-icon>
              </div>
            </div>
          </template>
          <v-list>
            <v-list-item
              v-for="([title, icon, to, target], i) in items"
              :key="i"
              :to="title != $t('OfficialDocs') ? to : undefined"
              :href="title == $t('OfficialDocs') ? to : undefined"
              :target="target"
            >
              <!-- <v-col sm="12" md="2" class="mr-3 pa-0" style="max-width: 35px">
                <app-icon :icon="icon" class="d-flex align-center" />
              </v-col> -->
              <template v-slot:prepend>
                <div class="d-flex align-center text-header-menu--item">
                  <app-icon :icon="icon" />
                </div>
              </template>
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import logo from "@/assets/logo.svg";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import AppIcon from "@/components/BaseComponent/AppIcon.vue";
import { useUserSetting, useNotification, useDateFormat, useConfig } from "@/composables";
import router from "@/router";

export default defineComponent({
  name: "HeaderBar",
  components: {
    AppIcon,
  },
  emits: ["render"],
  setup(_, { emit }) {
    const store = useStore();
    const { t } = useI18n();
    const { appLogo, appName } = useUserSetting();

    const user = computed(() => store.getters.getUser);
    const name = computed(() => user.value?.name || t("NoName"));
    const email = computed(() => user.value?.Username || t("NoEmail"));
    const isAdmin = computed(() => user.value?.role_id === 2);

    const {
      simNotificationItems,
      otaNotificationItems,
      iotsafeNotificationItems,
      notificationCount,
      simNotificationCount,
      otaNotificationCount,
      iotsafeNotificationCount,
      clearSimNotificationCount,
      clearOTANotificationCount,
      clearIoTSAFENotificationCount,
    } = useNotification();
    const tabs = computed(() => ["sim", "ota", "iotsafe"]);
    const tab = ref("sim");
    const isOpenNotifications = ref(false);
    const { showDate } = useDateFormat();

    const { OFFICIAL_DOCUMENT_LINK } = useConfig();

    const route = useRoute();
    const notificationClickHandler = (to: string | undefined) => {
      if (to) {
        if (route.path === to) {
          emit("render");
        } else {
          router.push(to);
        }
        isOpenNotifications.value = false;
      }
    };

    watch(
      () => isOpenNotifications.value,
      () => {
        switch (tab.value) {
          case "sim": {
            clearSimNotificationCount();
            break;
          }
          case "ota": {
            clearOTANotificationCount();
            break;
          }
          case "iotsafe": {
            clearIoTSAFENotificationCount();
            break;
          }
        }
      },
    );
    watch(
      () => tab.value,
      () => {
        switch (tab.value) {
          case "sim": {
            clearSimNotificationCount();
            break;
          }
          case "ota": {
            clearOTANotificationCount();
            break;
          }
          case "iotsafe": {
            clearIoTSAFENotificationCount();
            break;
          }
        }
      },
    );

    const items = computed(() => {
      return [
        [t("PersonalSettings"), "account", { name: "personal_setting" }],
        [t("OpenApiDocs"), "document", { name: "openapi_doc" }, "_blank"],
        [t("OfficialDocs"), "document", OFFICIAL_DOCUMENT_LINK, "_blank"],
        [t("logout"), "logout", { name: "logout" }],
      ];
    });

    const isEnableOTA = computed(() => process.env.VUE_APP_ENABLE_OTA === "1");
    const isEnableIoTSAFE = computed(() => process.env.VUE_APP_ENABLE_IOT_SAFE === "1");

    return {
      appName,
      logo: logo,
      items,
      name,
      email,
      appLogo,
      isAdmin,
      simNotificationItems,
      otaNotificationItems,
      iotsafeNotificationItems,
      notificationCount,
      simNotificationCount,
      otaNotificationCount,
      iotsafeNotificationCount,
      notificationClickHandler,
      isOpenNotifications,
      showDate,
      tabs,
      tab,
      isEnableOTA,
      isEnableIoTSAFE,
    };
  },
});
</script>
<style>
.text-header-menu-email {
  color: rgba(0, 0, 0, 0.87);
}
.text-header-menu-name {
  color: rgba(0, 0, 0, 0.6);
}
.loading {
  color: rgb(var(--v-theme-themeColor));
}
</style>
