import { ActionContext } from "vuex";

export interface NotificationItem {
  date: string;
  title: string;
  body: string;
  to?: string;
}

export enum NotificationType {
  SIM = "sim",
  OTA = "ota",
  IoTSAFE = "iotsafe",
}

export default {
  namespaced: true,
  state: {
    sim: {
      items: [] as Array<NotificationItem>,
      count: 0,
    },
    ota: {
      items: [] as Array<NotificationItem>,
      count: 0,
    },
    iotsafe: {
      items: [] as Array<NotificationItem>,
      count: 0,
    },
  },
  getters: {
    getItems: (state: any) => state.items,
    getCount: (state: any) => state.count,
    getAllCount: (state: any) => Object.keys(state).reduce((count, key) => count + state[key].count, 0),
    simNotificationItems: (state: any) => state[NotificationType.SIM].items,
    simNotificationCount: (state: any) => state[NotificationType.SIM].count,
    otaNotificationItems: (state: any) => state[NotificationType.OTA].items,
    otaNotificationCount: (state: any) => state[NotificationType.OTA].count,
    iotsafeNotificationItems: (state: any) => state[NotificationType.IoTSAFE].items,
    iotsafeNotificationCount: (state: any) => state[NotificationType.IoTSAFE].count,
  },
  mutations: {
    ADD_ITEM: (state: any, payload: { type: NotificationType; item: NotificationItem }) => {
      state[payload.type].items.unshift(payload.item);
      state[payload.type].items.splice(20, 1);
    },
    INCREASE_COUNT: (state: any, type: NotificationType) => (state[type].count += 1),
    CLEAR_COUNT: (state: any, type: NotificationType) => (state[type].count = 0),
    CLEAR_ALL_COUNT: (state: any) => {
      Object.keys(state).forEach((key: string) => {
        state[key].count = 0;
      });
    },
  },
  actions: {
    addSimItem: ({ commit }: ActionContext<any, any>, item: NotificationItem) => {
      commit("ADD_ITEM", { type: NotificationType.SIM, item });
      commit("INCREASE_COUNT", NotificationType.SIM);
    },
    clearSimCount: ({ commit }: ActionContext<any, any>) => commit("CLEAR_COUNT", NotificationType.SIM),
    addOTALogItem: ({ commit }: ActionContext<any, any>, item: NotificationItem) => {
      commit("ADD_ITEM", { type: NotificationType.OTA, item });
      commit("INCREASE_COUNT", NotificationType.OTA);
    },
    clearOTACount: ({ commit }: ActionContext<any, any>) => commit("CLEAR_COUNT", NotificationType.OTA),
    addIoTSAFELogItem: ({ commit }: ActionContext<any, any>, item: NotificationItem) => {
      commit("ADD_ITEM", { type: NotificationType.IoTSAFE, item });
      commit("INCREASE_COUNT", NotificationType.IoTSAFE);
    },
    clearIoTSAFECount: ({ commit }: ActionContext<any, any>) => commit("CLEAR_COUNT", NotificationType.IoTSAFE),
    clearAllCount: ({ commit }: ActionContext<any, any>) => commit("CLEAR_ALL_COUNT"),
  },
};
