<template>
  <div class="py-2 app-main-navbar">
    <div class="container d-flex justify-start align-center text-white">
      <v-btn
        variant="plain"
        v-for="item in menuItems"
        :key="item.title"
        v-show="item.show"
        :class="{ 'nav-item-active': item.active }"
        @click="() => onNaviagte(item.name)"
      >
        <app-icon :icon="item.icon" class="d-flex align-center mr-2" />
        {{ item.title }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import AppIcon from "@/components/BaseComponent/AppIcon.vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useConfig } from "@/composables";

export default defineComponent({
  name: "NavBar",
  components: {
    AppIcon,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const store = useStore();
    const { ROLE_MEMBER, ROLE_ADMIN, ROLE_ROOT } = useConfig();

    const role_id = computed(() => store.getters.getUserRoleId);

    const items = computed(() => {
      return [
        {
          title: t("SIM"),
          icon: "sim",
          name: { name: "home" },
          children: ["sim_item"],
          active: false,
          show: false,
          roles: [ROLE_ROOT, ROLE_ADMIN, ROLE_MEMBER],
        },
        {
          title: t("ota.label"),
          icon: "ota",
          name: { name: "ota_type_init" },
          children: ["ota_type", "ota_item"],
          active: false,
          show: false,
          roles: [ROLE_ROOT, ROLE_ADMIN, ROLE_MEMBER],
        },
        {
          title: t("IotSafe"),
          icon: "iot-safe",
          name: { name: "iot_safe_certificates" },
          children: ["iot_safe_certificate_item", "iot_safe_file_item", "iot_safe_files", "private_ca", "identity_template"],
          active: false,
          show: false,
          roles: [ROLE_ROOT, ROLE_ADMIN, ROLE_MEMBER],
        },
        {
          title: t("Member"),
          icon: "member",
          name: { name: "member_list" },
          children: [],
          active: false,
          show: false,
          roles: [ROLE_ROOT, ROLE_ADMIN, ROLE_MEMBER],
        },
        {
          title: t("Setting"),
          icon: "settings",
          name: { name: "settings" },
          children: [
            "settingTheme",
            "settingMail",
            "settingNotifyPassword",
            "settingPassword",
            "settingGenerate",
            "settingSms",
            "settingAccessRestrictions",
            "settingSim",
          ],
          active: false,
          show: false,
          roles: [ROLE_ROOT, ROLE_ADMIN, ROLE_MEMBER],
        },
        {
          title: t("Log"),
          icon: "logs",
          name: { name: "logs" },
          children: ["systemLog"],
          active: false,
          show: false,
          roles: [ROLE_ROOT, ROLE_ADMIN, ROLE_MEMBER],
        },
      ];
    });

    const currentRouteName = computed(() => route.name);

    const menuItems = computed(() => {
      const list = [...items.value];
      return list.map((item: any) => {
        let show = item.roles.indexOf(role_id.value) >= 0 ? true : false;
        if (item.title === t("ota.label")) {
          show = show && process.env.VUE_APP_ENABLE_OTA === "1";
        }
        if (item.title === t("IotSafe")) {
          show = show && process.env.VUE_APP_ENABLE_IOT_SAFE === "1";
        }
        return {
          ...item,
          active: currentRouteName.value === item.name.name || item.children.indexOf(currentRouteName.value) >= 0 ? true : false,
          show,
        };
      });
    });

    const onNaviagte = (to: Record<string, any>) => {
      if (["ota_type", "ota_type_init"].includes(currentRouteName.value as any) && to.name === "ota_type_init") {
        return;
      }
      router.push(to);
    };

    return {
      currentRouteName,
      menuItems,
      onNaviagte,
    };
  },
});
</script>
